<template>
  <div id="app">
    <index />
  </div>
</template>

<script>
import index from './views/index'

export default {
  name: 'App',

  components: {
    index,
  },
}
</script>
<style>

</style>

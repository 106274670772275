<template>
  <div class='body'>
    <!--    搜索栏-->
    <div class='top_1'>

      <el-date-picker
        v-model='queryParams.alarmTime'
        :editable='false'
        style='width: 195%;width: 45%'
        :picker-options='pickerOptionsStart'
        size='small'
        @change='findDate'
        value-format='yyyy-MM-dd'
        placeholder='选择日期时间'>
      </el-date-picker>
      <el-select style='width: 44.5%;' @change='findAlarmName' clearable size='small' v-model='queryParams.projectName'
                 placeholder='请选择报警水位'>
        <el-option
          v-for='item in options'
          :key='item.value'
          :label='item.label'
          :value='item.value'>
        </el-option>
      </el-select>

    </div>
    <!--    报警历史卡片样式-->
    <div class='alarm_card' v-for='(item,index) in tabelData' :key='index'>
      <div v-show="item.isPush=='0'" class='el_card'>
        <!--    报警历史信息样式-->
        <div class='el_card_0'>
          <div v-if='findHuawei==false' class='el_card_0_right_top'>
            <label style='padding-top: 3%;'>已处理</label>
          </div>
          <div v-if='findHuawei==true' class='el_card_0_right_top_huawei'>
            <div style='padding-top: 4%;'>已处理</div>
          </div>

          <!--          图片和水位-->
          <div class='el_card_0_text_image'>
            <img :src='alarmUrl' width='25' height='25px' />
            <div style='margin-left: 20%;margin-top: -15%;'>
              <label>{{ item.projectName }}</label>
            </div>
          </div>
          <!--          分隔线-->
          <div class='bottom_line_alarm'></div>
          <!--          报警时间-->
          <div class='el_card_alarm_time'>
            <label>报警时间 :</label>
            <div style='margin-left: 22%;margin-top: -18px;font-size: 14px;color: #8f9297'>{{ item.alarmTime }}</div>
          </div>
          <div class='el_card_alarm_time'>
            <label>接警人 :</label>
            <div style='margin-left: 18%;margin-top: -18px;font-size: 14px;color: #8f9297'>{{ item.reserveUser }}</div>
          </div>
          <div class='el_card_alarm_time'>
            <label>已处理结果 :</label>
            <div style='margin-left: 25%;margin-top: -18px;font-size: 14px;color: #8f9297'>短信或语音下发成功</div>
          </div>
        </div>
      </div>
      <div v-show="item.isPush=='1'" class='el_card_red'>
        <!--    报警历史信息样式-->
        <div class='el_card_0'>
          <div v-if='findHuawei==false' class='el_card_0_right_top_red'>
            <label style='padding-top: 3%;'>未处理</label>
          </div>
          <div v-if='findHuawei==true' class='el_card_0_right_huawei'>
            <div style='padding-top: 4%;'>未处理</div>
          </div>
          <!--          图片和水位-->
          <div class='el_card_0_text_image'>
            <img :src='alarmUrl' width='25' height='25px' />
            <div style='margin-left: 20%;margin-top: -15%;'>
              <label>{{ item.projectName }}</label>
            </div>
          </div>
          <!--          分隔线-->
          <div class='bottom_line_alarm_red'></div>
          <!--          报警时间-->
          <div class='el_card_alarm_time'>
            <label>报警时间 :</label>
            <div style='margin-left: 22%;margin-top: -18px;font-size: 14px;color: #8f9297'>{{ item.alarmTime }}</div>
          </div>
          <div class='el_card_alarm_time'>
            <label>接警人 :</label>
            <div style='margin-left: 18%;margin-top: -18px;font-size: 14px;color: #8f9297'>{{ item.reserveUser }}</div>
          </div>
          <div class='el_card_alarm_time'>
            <label>未处理原因 :</label>
            <div style='margin-left: 25%;margin-top: -18px;font-size: 14px;color: #8f9297'>短信或语音下发失败</div>
          </div>
        </div>
      </div>
    </div>
    <template>
      <div v-if='tabelData.length>0' style='margin-top: 20%;text-align: center;color: #8f9297;flex: auto'>
        ——当前仅显示近7天报警历史——
      </div>
    </template>
  </div>
</template>
<script>
import axios from 'axios'

var sUserAgent = navigator.userAgent.toLowerCase()
const isHuawei = sUserAgent.match(/huawei/i) == 'huawei'
export default {
  name: 'alarm',
  data() {
    return {
      value: '',
      findHuawei: isHuawei,
      alarmUrl: require('/src/assets/images/水浸图标2.png'),
      drawer: false,
      date: undefined,
      direction: 'rtl',
      queryParams: {
        alarmTime:'',
        projectName:undefined
      },
      value1: '',
      drawerWidth: '100%',
      tabelData: [],
      pickerOptionsStart: {
        // 时间不能大于当前时间
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        },
      },
      options: [{
        value: '1',
        label: '0厘米浸水',
      }, {
        value: '2',
        label: '10厘米浸水',
      }, {
        value: '3',
        label: '20厘米浸水',
      }, {
        value: '4',
        label: '30厘米浸水',
      }, {
        value: '5',
        label: '40厘米浸水',
      }],
    }
  },
  created() {
    this.firstLoad()
  },
  methods: {
    firstLoad() {
      axios.get('http://water.5iot.com/prod-api/alarm/findAll').then(res => {
        this.tabelData = res.data.content
      })
    },
    findDate(va) {
      if (va == null) {
        this.firstLoad()
      }
      this.queryParams.alarmTime = va
      axios.get('http://water.5iot.com/prod-api/alarm/findByDate', { params: this.queryParams }).then(res => {
        this.tabelData = res.data
      })
    },
    findAlarmName(al) {
      if (al =="") {
        this.firstLoad()
      }
      if (al == '1') {
        this.queryParams.projectName = "0 厘米浸水"
      } else if (al == '2') {
        this.queryParams.projectName = "10 厘米浸水"
      } else if (al == '3') {
        this.queryParams.projectName = "20 厘米浸水"
      } else if (al == '4') {
        this.queryParams.projectName = "30 厘米浸水"
      } else if (al == '5') {
        this.queryParams.projectName = "40 厘米浸水"
      }
      axios.get('http://water.5iot.com/prod-api/alarm/findAlarmName', { params: this.queryParams }).then(res => {
        this.tabelData = res.data
      })
    },
    handleClose() {
      this.drawer = false
    },
  },
}
</script>

<style lang='scss'>

//.top_2_line{
//  width: 93%;
//  height: 1px;
//  position: fixed;
//  margin-top: 12%;
//  background-color: rgb(238, 238, 240);
//}
.alarm_card {
  margin-top: 20%;
  margin-left: 0px;
}

.el_card_red {
  width: 98%;
  height: 130px;
  margin-bottom: -15%;
  background-color: #FFFFFF;
  border-radius: 6px;
  border: 1px solid rgb(255, 211, 210);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.el_card {
  width: 98%;
  height: 130px;
  margin-bottom: -15%;
  background-color: #FFFFFF;
  border-radius: 6px;
  border: 1px solid rgb(145, 213, 255);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.el_card_0 {
  width: 100%;
  height: 40%;
  //background-color: #f5222d;
}

.el_card_0_right_top {
  width: 18%;
  height: 49%;
  border-radius: 6px;
  color: #FFFFFF;
  margin-left: 82%;
  text-align: center;
  background-color: rgb(56, 148, 255);
}
.el_card_0_right_top_huawei{
  width: 18%;
  height: 45%;
  border-radius: 6px;
  color: #FFFFFF;
  margin-left: 82%;
  text-align: center;
  background-color: rgb(56, 148, 255);
 }
.el_card_0_right_top_red {
  width: 18%;
  height: 49%;
  border-radius: 6px;
  color: #FFFFFF;
  margin-left: 82%;
  text-align: center;
  background-color: rgb(242, 97, 97);
}
.el_card_0_right_huawei{
  width: 18%;
  height: 45%;
  border-radius: 6px;
  color: #FFFFFF;
  margin-left: 82%;
  text-align: center;
  background-color: rgb(242, 97, 97);
}
.el_card_0_text_image {
  justify-content: space-between;
  width: 50%;
  margin-left: 6%;
  height: 50%;
  margin-top: -20px;
  //background-color: #7171C6;
}

.bottom_line_alarm {
  margin-left: 6.5%;
  width: 93%;
  margin-top: 6px;
  border-top: 1px dashed rgb(145, 213, 255);
}

.bottom_line_alarm_red {
  margin-left: 6.5%;
  width: 93%;
  margin-top: 6px;
  border-top: 1px dashed rgb(255, 211, 210);
}

.el_card_alarm_time {
  margin-left: 6.5%;
  width: 93%;
  margin-top: 5px;
  color: #8f9297;
  height: 50%;
  font-size: 14px;
  //background-color: #7171C6;
}

.top_1 {
  width: 100%;
  height: 5%;
  position: fixed;
  //background-color: #f5222d;
}

.body {
  overflow-x: hidden;
  justify-content: space-between;
  position: fixed;
  //background-color: #7171C6;
}
</style>

<template>
  <div class='ch_body'>
    <!--    搜索栏-->
    <div class='ch_search'>
      <el-date-picker
        v-model='queryParams.changeTime'
        :editable='false'
        style='width: 195%;width: 45%'
        :picker-options='pickerOptionsStart'
        size='small'
        @change='findChDate'
        value-format='yyyy-MM-dd'
        placeholder='选择日期时间'>
      </el-date-picker>
      <el-select style='width: 44.5%;' @change='findChangeName' clearable size='small' v-model='queryParams.projectName'
                 placeholder='请选择报警水位'>
        <el-option
          v-for='item in options'
          :key='item.value'
          :label='item.label'
          :value='item.value'>
        </el-option>
      </el-select>
    </div>
    <div class='ch_card' v-for='(item,index) in tabelData' :key='index'>
      <!-- 卡片样式-->
      <div class='ch_el_card'>
        <!-- 水位-->
        <div class='ch_top_right'>
          <div style=' padding-top: 5%;    margin-left: 9%;'>{{item.projectName}}</div>

        </div>
        <div class='ch_left_center' />
        <div class='ch_content'>
          <div v-if="item.changeName=='手机号'">
            <img :src='phoneImg' width='8%' height='8%' style='margin-left: 3%;'/>
          </div>
          <div v-if="item.changeName=='接警人'">
            <img :src='personImg' width='8%' height='8%' style='margin-left: 3%;'/>
          </div>
          <div style="margin-left: 16%;margin-top: -8.5%;font-size: 16px;font-weight: 550">
            <label>变更项 :{{item.changeName}}</label>
          </div>
          <div style='margin-top: 2%;'>
            <img :src="chTimeImg" width='8%' height='8%' style='margin-left: 3%;margin-top: 2%'>
          </div>
          <div style="margin-left: 16%;margin-top: -8.5%;font-size: 14px;color: #8f9297">
            <label>变更时间 : {{item.changeTime}}</label>
          </div>
          <div class="ch_bottom_line"/>
          <div class="ch_bf_af">
            <div>变更前 : {{item.changeBefore}}</div>
            <div v-if='findHuawei==false' style='margin-left: 52%;margin-top: -6%'>变更后 : {{item.changeAfter}}</div>
            <div v-if='findHuawei==true' style='margin-left: 52%;margin-top: -5%'>变更后 : {{item.changeAfter}}</div>

          </div>
        </div>
      </div>
    </div>
    <template>
      <div v-show='tabelData.length>0' style='margin-top: 20%;text-align: center;color: #8f9297'>——当前仅显示近一个月变更记录——</div>
    </template>
  </div>
</template>

<script>
import axios from 'axios'

var sUserAgent = navigator.userAgent.toLowerCase()
const isHuawei = sUserAgent.match(/huawei/i) == 'huawei'
export default {
  name: 'alarm',
  data() {
    return {
      findHuawei: isHuawei,
      drawer: false,
      direction: 'rtl',
      drawerWidth: '100%',
      tabelData: [],
      form: {},
      queryParams: {
        alarmTime:'',
        projectName:undefined
      },
      options: [{
        value: '1',
        label: '0厘米浸水',
      }, {
        value: '2',
        label: '10厘米浸水',
      }, {
        value: '3',
        label: '20厘米浸水',
      }, {
        value: '4',
        label: '30厘米浸水',
      }, {
        value: '5',
        label: '40厘米浸水',
      }],
      pickerOptionsStart: {
        // 时间不能大于当前时间
        disabledDate: (time) => {
          return time.getTime() > Date.now()
        },
      },
      phoneImg:require('/src/assets/images/phone.png'),
      personImg:require('/src/assets/images/人员变动.png'),
      chTimeImg:require('/src/assets/images/chTime.png'),
    }
  },
  created() {
    this.chLoad()
  },
  methods: {
    chLoad() {
      axios.get('http://water.5iot.com/prod-api/change/findAll').then(res => {
        this.tabelData = res.data.content
      })
    },
    findChDate(va){
      if (va == null){
        this.chLoad()
      }
      this.queryParams.changeTime = va
      axios.get('http://water.5iot.com/prod-api/change/findByChDate', { params: this.queryParams }).then(res => {
        this.tabelData = res.data
      })
    },
    findChangeName(al){
      if (al =="") {
        this.chLoad()
      }
      if (al == '1') {
        this.queryParams.projectName = "0 厘米浸水"
      } else if (al == '2') {
        this.queryParams.projectName = "10 厘米浸水"
      } else if (al == '3') {
        this.queryParams.projectName = "20 厘米浸水"
      } else if (al == '4') {
        this.queryParams.projectName = "30 厘米浸水"
      } else if (al == '5') {
        this.queryParams.projectName = "40 厘米浸水"
      }
      axios.get('http://water.5iot.com/prod-api/change/findChangeName', { params: this.queryParams }).then(res => {
        this.tabelData = res.data
      })
    },
    handleClose() {
      this.drawer = false
    },
  },
}
</script>

<style lang='scss'>
.ch_body {
  overflow-x: hidden;
  justify-content: space-between;
  position: fixed;
}

.ch_card {
  margin-top: 20%;
  margin-left: 0px;
}

.ch_el_card {
  width: 98%;
  height: 130px;
  padding-top: 7px;
  margin-bottom: -15%;
  background-color: #FFFFFF;
  border-radius: 6px;
  //border: 1px solid rgb(145, 213, 255);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.ch_left_center {
  width: 1.5%;
  height: 20%;
  margin-top: 7%;
  background-color: rgb(110, 171, 248);
}

.ch_content {
  width: 93%;
  height: 73%;
  //background-color: #f5222d;
  margin-left: 5%;
  margin-top: -16%;
}
.ch_bottom_line{
  width: 100%;
  height: 1%;
  margin-top: 3%;
  background-color: rgba(227, 227, 229, 0.86);
}
.ch_bf_af{
  width: 100%;
  height: 30%;
  font-size: 14px;
  color: #8f9297;
  margin-top: 2%;
  //background-color: #27d467;
}
.ch_top_right {
  width: 20%;
  height: 18%;
  font-size: 12px;
  color: rgb(110, 171, 248);
  margin-left: 79%;
  border-radius: 6px;
  background-color: rgb(237, 245, 254);
}
.ch_search {
  width: 100%;
  height: 5%;
  position: fixed;
  //background-color: #f5222d;
}
</style>

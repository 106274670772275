<template>
  <div class='allTotal' :style="{backgroundImage: 'url(' + backUrl + ')',width:'100%',height:'100%'}">
    <!--头部导航栏-->
    <div class='top_taber'>
      <!--导航bar-->
      <div class='list-item'>
        <div class='box' v-for='(item,index) in topContent' :key='index' @click='tableBar(index)'>
          <div style='margin-left: 16px'><img :src='item.images' height='40px' width='40px' /></div>
          <div v-show='firstIndex' :class="{'bar_bottom':index==0}" class='top_item_title'>{{ item.topTitle }}<p
            v-show='firstIndex' :class="{'bottom_line':index==0}" /></div>
          <div v-show='secondIndex' :class="{'bar_bottom':index==1}" class='top_item_title'>{{ item.topTitle }}<p
            v-show='secondIndex' :class="{'bottom_line':index==1}" /></div>
          <div v-show='threeIndex' :class="{'bar_bottom':index==2}" class='top_item_title'>{{ item.topTitle }}<p
            v-show='threeIndex' :class="{'bottom_line':index==2}" /></div>
        </div>
      </div>
      <!--      设备基础信息-->
      <div v-if='firstIndex' style='height: 104%;width: 100%;'>
        <div class='card_info'>
          <div class='device_info'>
            <div style='    padding-bottom: 2px;'></div>
            <div class='info_one'>
              <p class='info_left_signal' />
              <div class='text_type'>
                <label>型号 : WSFNB-330</label>
              </div>
            </div>

            <div class='info_two'>
              <p class='info_left_signal' />
              <div class='text_address'>
                <label>安装地址 :</label>
                <label style='margin-left: 2%'>{{this.tabelData[0].address}}</label>
                <el-button @click='updateAddress(tabelData[0])' style='border-radius: 4px;margin-left: 3%;font-size: 14px;font-weight: 550' size='mini' type="text">修改</el-button>
              </div>
            </div>
            <div class='info_three'>
              <p class='info_left_signal' />
              <div class='text_address'>
                <label class='device_info_font'>设备信息 : </label>
                <label v-for='item in tabelData' :key='item.id' v-show='item.id==imeiValue' @click='showImei(item.imei)' class='device_info_code'>{{ item.imei }}</label>
                <div class='set_images'>
                  <img v-copy:a='sbn' :src='url' width='20px' height='20px' />
                </div>
                <div style='margin-left: 290px;margin-top: -23px;font-size: 14px;color: rgb(24,144,255)'>复制</div>
              </div>
            </div>
            <!--              设备实时数据展示-->
            <div class='device_data_list'>
              <div class='device-box' v-for='(item,index) in deviceData' :key='index'>
                <label v-if='index==1' :style='{ color: getColorByIndex(index) }' class='device_data_text'>{{ SDU
                  }}%</label>
                <label v-if='index==0' :style='{ color: getColorByIndex(index) }' class='device_data_text'>{{ WDU
                  }}℃</label>
                <label v-if='index==2' :style='{ color: getColorByIndex(index) }' class='device_data_text'>{{ DYA
                  }}V</label>
                <label v-if='index==3' :style='{ color: getColorByIndex(index) }' class='device_data_text'>{{ XHAO
                  }}(优)</label>
                <label v-if='index==4' :style='{ color: getColorByIndex(index) }' class='device_data_text'>{{ GZ
                  }}lux</label>
                <br />
                <label style='font-size: 14px;font-weight:550;color: rgb(162,162,162)'>{{ item.deviceTitle }}</label>
              </div>
            </div>
            <!--      报警信息-->
            <div class='alarm_info'>
              <div v-for='item in tabelData' :key='item.id' class='alarm_box'>
                <el-card v-if='findHuawei===false' :class="item.realTimeData == '1'? 'one' : 'two'">
                  <div style='margin-bottom: 6px;padding-top: 7px;margin-left: 30px;'>{{ item.name }}
                  </div>
                  <div v-if="item.realTimeData === '0'" class='border_da_0' />
                  <div v-if="item.realTimeData === '1'" class='border_da_1' />
                  <div style='margin-left: 31px;padding-top: 8px;font-size: 14px;color: rgb(163, 166, 167);'>
                    接警人:{{ item.reserveUser }}
                  </div>
                  <template>
                    <div v-if="item.realTimeData === '0'" class='button_card' @click='handleClick(item)'>
                      <div style='padding-top: 15px;color: rgb(255,255,255)'>
                        编辑
                      </div>
                    </div>
                    <div v-if="item.realTimeData === '1'" class='button_card_red' @click='handleClick(item)'>
                      <div style='padding-top: 15px;color: rgb(255,255,255)'>
                        编辑
                      </div>
                    </div>
                  </template>
                </el-card>
                <el-card v-if='findHuawei===true' :class="item.realTimeData == '1'? 'one' : 'two'">
                  <div style='margin-bottom: 6px;padding-top: 7px;margin-left: 30px;'>{{ item.name }}
                  </div>
                  <div v-if="item.realTimeData === '0'" class='border_da_0' />
                  <div v-if="item.realTimeData === '1'" class='border_da_1' />
                  <div style='margin-left: 31px;padding-top: 8px;font-size: 14px;color: rgb(163, 166, 167);'>
                    接警人:{{ item.reserveUser }}
                  </div>
                  <template>
                    <div v-if="item.realTimeData === '0'" class='button_card_huawei' @click='handleClick(item)'>
                      <div style='padding-top: 15px;color: rgb(255,255,255)'>
                        编辑
                      </div>
                    </div>
                    <div v-if="item.realTimeData === '1'" class='button_card_red_huawei' @click='handleClick(item)'>
                      <div style='padding-top: 15px;color: rgb(255,255,255)'>
                        编辑
                      </div>
                    </div>
                  </template>
                </el-card>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--        报警历史-->
      <alarm class='alarm_card_info' ref='showAl' v-if='secondIndex' />
      <!--        变更记录-->
      <change class='alarm_card_info' v-if='threeIndex' ref='showCh' />
    </div>
    <el-dialog :modal-append-to-body='false' :visible.sync='open' width='350px' title='修改' center>
      <el-form ref='form' :model='form' label-width='70px'>
        <el-row>
          <el-col span='20'>
            <el-form-item label='接警人'>
              <el-input
                @input='resUser'
                style='width: 240px'
                v-model='form.reserveUser'
                placeholder='请输入'
                class='short-input'
                :maxlength='50'
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col span='20'>
            <el-form-item label='手机号'>
              <el-input
                @input='phoneNum'
                style='width: 240px'
                v-model='form.phoneNumber'
                placeholder='请输入手机号'
                class='short-input'
                :maxlength='50'
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submit'>确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog :modal-append-to-body='false' :visible.sync='openImei' width='350px' title='切换设备' center>
      <el-select  style='width: 100%;' @change='setImeiValue'  clearable size='small' v-model='imeiName'
                  placeholder='请选择切换设备编号'>
        <el-option
          v-for='item in dataImei'
          :key='item.Id'
          :label='item.imei'
          :value='item.Id'>
        </el-option>
      </el-select>
      <span slot='footer' class='dialog-footer'>
      </span>
    </el-dialog>
    <el-dialog :modal-append-to-body='false' :visible.sync='openAddress' width='350px' title='修改地址' center>
      <el-form ref='addressForm' :model='addressForm' label-width='70px'>
        <el-row>
          <el-col :span='10'>
            <el-form-item label='地址'>
              <el-input
                @input='phoneNum'
                style='width: 240px'
                v-model='addressForm.address'
                placeholder='请输入地址'
                class='short-input'
                :maxlength='50'
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitAdd'>确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import axios from 'axios'
import mqtt from 'mqtt'
import change from './changeRecored/change'
import alarm from './alarmRecord/alarm'
var date = new Date()
var sUserAgent = navigator.userAgent.toLowerCase()
const isHuawei = sUserAgent.match(/huawei/i) == 'huawei'
export default {
  name: 'index',
  components: { change, alarm },
  data() {
    return {
      imeiValue:'1',
      imeiName:undefined,
      dataImei:[{imei:"867705072802263",Id:0},{imei:"867705072802222",Id:1},{imei:"867705072802412",Id:2},{imei:"861038072388026",Id:3},{imei:"867705072802438",Id:4}],

      url: require('/src/assets/images/复制.png'),
      backUrl: require('/src/assets/images/top1.jpg'),
      topContent: [
        { topTitle: '实时数据', images: require('/src/assets/images/水浸图标1.png') },
        { topTitle: '报警历史', images: require('/src/assets/images/水浸图标2.png') },
        { topTitle: '变更记录', images: require('/src/assets/images/水浸图标3.png') },
      ],
      deviceData: [
        { deviceTitle: '空气温度', data: this.WDU + '℃' },
        { deviceTitle: '空气湿度', data: this.SDU + '%' },
        { deviceTitle: '供电电压', data: this.DYA + 'V' },
        { deviceTitle: '信号强度', data: this.XHAO + '(优)' },
        { deviceTitle: '光照度', data: this.GZ + 'lux' },
      ],
      net: require('net'),
      SDU: '',
      WDU: '',
      DYA: '',
      XHAO: '',
      GZ: '',
      findHuawei: isHuawei,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
      id: '',
      form: {},
      addressForm:{},
      realData: '',
      sbn: undefined,
      open: false,
      tabelData: [],
      connection: {
        protocol: 'ws',
        host: '124.221.1.174',
        // ws: 8083; wss: 8084
        port: 8083,
        endpoint: '/mqtt',
        clean: true,
        keepalive: 100,
        connectTimeout: 1000, // ms
        reconnectPeriod: 4000, // ms
        clientId: '' + date.getHours() + date.getMinutes() + date.getSeconds(), //math.random()*10000+'',//'client-mao2080',
        // auth
        username: 'sadewin',
        password: 'sadewin',
      },
      subscription: {
        topic: '/senan/001/',
        qos: 0,
      },
      beforeUser: undefined,
      beforePhone: undefined,
      afterUser: undefined,
      afterPhone: undefined,
      publish: {
        topic: 'topic/browser',
        qos: 0,
        payload: '{ "msg": "Hello, I am browser." }',
      },
      openAddress:false,
      receiveNews: '',
      qosList: [0, 1, 2],
      client: {
        connected: false,
      },
      //页面切换绑定值
      firstIndex: true,
      secondIndex: false,
      threeIndex: false,
      leve: undefined,
      beforForm: {},
      changeForm: {},
      alarmForm: {},
      openImei:false,
      subscribeSuccess: false,
      connecting: false,
      retryTimes: 0,
    }
  },
  created() {
    this.query()
    // this.connect()
    this.createConnection()
  },
  mounted() {

  },
  methods: {
    showImei(){
      this.openImei=true;
    },
    getColorByIndex(index) {
      const colors = ['rgba(123,175,63)', 'rgba(64,149,229)', '#FF0000', 'rgba(232,154,63)'] // 红，绿，蓝，黄
      return colors[index % colors.length]
    },
    getTitleText(index) {
      const colors = ['rgba(123,175,63)', 'rgba(64,149,229)', 'rgba(232,154,63)'] // 红，绿，蓝，黄
      return colors[index % colors.length]
    },
    setImeiValue(va){
      this.imeiValue=va
      if (this.imeiValue=='0'){
        this.tabelData[0].imei="867705072802263"
        this.imeiName="867705072802263"
      }else if (this.imeiValue=='1'){
        this.imeiName="867705072802222"
        this.tabelData[0].imei="867705072802222"
      }else if (this.imeiValue=='2'){
        this.imeiName="867705072802412"
        this.tabelData[0].imei="867705072802412"
      }else if (this.imeiValue=='3'){
        this.imeiName="861038072388026"
        this.tabelData[0].imei="861038072388026"
      }else if (this.imeiValue=='4'){
        this.imeiName="867705072802438"
        this.tabelData[0].imei="867705072802438"
      }
      this.form=this.tabelData[0];
      this.$axios.put('http://water.5iot.com/prod-api/water/update', this.form).then(res => {
        if (res.data == 'success') {
          this.$message.success('已发送 ！')
          this.open = false
        }
      })
      this.openImei=false;
      window.location.reload()
    },
    updateAddress(va){
      this.openAddress=true;
      this.addressForm=va
    },
    tableBar(va) {
      if (va == '1') {
        this.secondIndex = true
        this.firstIndex = false
        this.firstIndex = false
        this.threeIndex = false
        this.$refs.showAl.firstLoad()
      }
      if (va == '0') {
        this.secondIndex = false
        this.firstIndex = true
        this.threeIndex = false
      }
      if (va == '2') {
        this.threeIndex = true
        this.secondIndex = false
        this.firstIndex = false
        this.$refs.showCh.chLoad()
      }
    },
    initData() {
      this.client = {
        connected: false,
      }
      this.retryTimes = 0
      this.connecting = false
      this.subscribeSuccess = false
    },
    handleOnReConnect() {
      this.retryTimes += 1
      if (this.retryTimes > 5) {
        try {
          this.client.end()
          this.initData()
          this.$message.error('Connection maxReconnectTimes limit, stop retry')
        } catch (error) {
          this.$message.error(error.toString())
        }
      }
    },
    query() {
      this.$axios.get('http://water.5iot.com/prod-api/water/findAll').then(res => {
        this.tabelData = res.data.content
      })
    },
    handleClick(row) {
      this.beforeUser = row.reserveUser
      this.beforePhone = row.phoneNumber
      this.form = row
      this.open = true
    },
    resUser(va) {
      this.afterUser = va
    },
    phoneNum(sa) {
      this.afterPhone = sa
    },

// 获取当前时间
    getNowTime() {
      const yy = new Date().getFullYear()
      const MM = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1)
      const dd = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate()
      const HH = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours()
      const mm = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes()
      const ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds()
      return yy + '-' + MM + '-' + dd + ' ' + HH + ':' + mm + ':' + ss
    },
    submit() {
      var localOffset = date.getTimezoneOffset() * 60000 // 转换为毫秒
      var localDate = new Date(date.getTime() - localOffset)
      this.$axios.put('http://water.5iot.com/prod-api/water/update', this.form).then(res => {
        if (res.data == 'success') {
          this.$message.success('已发送 ！')
          this.open = false
          window.location.reload()
        }
      })
      if (this.afterUser !== undefined) {
        this.changeForm.changeBefore = this.beforeUser
        this.changeForm.changeAfter = this.afterUser
        this.changeForm.changeName = '接警人'
        this.changeForm.projectName = this.form.name
        this.changeForm.changeTime = localDate
        // changeBefore=null, changeName=null, changeAfter=null, projectName=null=
        axios.post('http://water.5iot.com/prod-api/change/save', this.changeForm)
      }
      if (this.afterPhone !== undefined) {
        this.changeForm.changeBefore = this.beforePhone
        this.changeForm.changeAfter = this.afterPhone
        this.changeForm.changeName = '手机号'
        this.changeForm.projectName = this.form.name
        this.changeForm.changeTime = localDate

        // changeBefore=null, changeName=null, changeAfter=null, projectName=null=
        axios.post('http://water.5iot.com/prod-api/change/save', this.changeForm)
      }
    },
    submitAdd(){
      var localOffset = date.getTimezoneOffset() * 60000 // 转换为毫秒
      var localDate = new Date(date.getTime() - localOffset)
      this.$axios.put('http://water.5iot.com/prod-api/water/update', this.addressForm).then(res => {
        if (res.data == 'success') {
          this.$message.success('已发送 ！')
          this.open = false
          window.location.reload()
        }
      })
      // if (this.afterUser !== undefined) {
      //   this.changeForm.changeBefore = this.beforeUser
      //   this.changeForm.changeAfter = this.afterUser
      //   this.changeForm.changeName = '接警人'
      //   this.changeForm.projectName = this.addressForm.name
      //   this.changeForm.changeTime = localDate
      //   // changeBefore=null, changeName=null, changeAfter=null, projectName=null=
      //   axios.post('http://water.5iot.com/prod-api/change/save', this.changeForm)
      // }
      // if (this.afterPhone !== undefined) {
      //   this.changeForm.changeBefore = this.beforePhone
      //   this.changeForm.changeAfter = this.afterPhone
      //   this.changeForm.changeName = '手机号'
      //   this.changeForm.projectName = this.form.name
      //   this.changeForm.changeTime = localDate
      //
      //   // changeBefore=null, changeName=null, changeAfter=null, projectName=null=
      //   axios.post('http://water.5iot.com/prod-api/change/save', this.changeForm)
      // }
    },
    saveAlarm(data) {
      this.alarmForm.projectName = data.name
      this.alarmForm.reserveUser = data.reserveUser
      this.alarmForm.alarmRegion = this.tabelData[0].address
      this.alarmForm.reserveUserPhone = data.phoneNumber
      var nowTime = this.getNowTime()
      this.alarmForm.alarmTime = nowTime
      axios.post('http://water.5iot.com/prod-api/alarm/save', this.alarmForm)
    },
    sendMessage(data) {
      axios.put('http://water.5iot.com/prod-api/water/sendMessages', data)
    },
    createConnection() {
      try {
        this.connecting = true
        const { protocol, host, port, endpoint, ...options } = this.connection
        const connectUrl = `${protocol}://${host}:${port}${endpoint}`
        this.client = mqtt.connect(connectUrl, options)
        if (this.client.on) {
          this.client.on('connect', () => {
            this.connecting = false
            this.$axios.get('http://water.5iot.com/prod-api/water/findAll').then(res => {
              this.tabelData = res.data.content
              this.imeiName=this.tabelData[0].imei
              this.doSubscribe(this.imeiName)
            })
          })
          this.client.on('reconnect', this.handleOnReConnect)
          this.client.on('error', error => {
            console.log('Connection failed', error)
          })
          this.client.on('message', (topic, message) => {
            this.receiveNews = this.receiveNews.concat(message)
            var msg_str1 = this.receiveNews.replace(/;/g, ',')
            var msg_str = msg_str1.replace(/'/g, '"')
            var parse = JSON.parse(msg_str)
            this.tabelData[0].realTimeData = parse.a1
            this.tabelData[1].realTimeData = parse.a2
            this.tabelData[2].realTimeData = parse.a3
            this.tabelData[3].realTimeData = parse.a4
            this.tabelData[4].realTimeData = parse.a5
            if (this.tabelData[0].realTimeData === '1') {
              this.$message({
                type: 'info',
                message: '您好，位于'+this.tabelData[0].address+'的智能设备已触发' + '一级报警，请您尽快确认并处理。',
              })
              this.saveAlarm(this.tabelData[0])
              this.tabelData[0].level = '一级'
              this.sendMessage(this.tabelData[0])
            }
            if (this.tabelData[1].realTimeData === '1') {
              this.$message({
                type: 'info',
                message: '您好，位于'+this.tabelData[0].address+'的智能设备已触发' + '二级级报警，请您尽快确认并处理。',
              })
              this.tabelData[1].level = '二级'
              this.saveAlarm(this.tabelData[1])
              this.sendMessage(this.tabelData[1])
            }
            if (this.tabelData[2].realTimeData === '1') {
              this.$message({
                type: 'info',
                message: '您好，位于'+this.tabelData[0].address+'的智能设备已触发' + '三级报警，请您尽快确认并处理。',
              })
              this.tabelData[2].level = '三级'
              this.saveAlarm(this.tabelData[2])
              this.sendMessage(this.tabelData[2])
            }
            if (this.tabelData[3].realTimeData === '1') {
              this.$message({
                type: 'info',
                message: '您好，位于'+this.tabelData[0].address+'的智能设备已触发' + '四级报警，请您尽快确认并处理。',
              })
              this.tabelData[3].level = '四级'
              this.saveAlarm(this.tabelData[3])
              this.sendMessage(this.tabelData[3])
            }
            if (this.tabelData[4].realTimeData === '1') {
              this.$message({
                type: 'info',
                message: '您好，位于'+this.tabelData[0].address+'的智能设备已触发' + '五级报警，请您尽快确认并处理。',
              })
              this.tabelData[4].level = '五级'
              this.saveAlarm(this.tabelData[4])
              this.sendMessage(this.tabelData[4])
            }
            this.SDU = parse.sd
            this.WDU = parse.wd
            this.DYA = parse.dy
            this.XHAO = parse.qd
            this.sbn = parse.sbh
            this.GZ = parse.gz
          })
        }
      } catch (error) {
        this.connecting = false
        console.log('mqtt.connect error', error)
      }
    },
    // subscribe topic
    // https://github.com/mqttjs/MQTT.js#mqttclientsubscribetopictopic-arraytopic-object-options-callback
    doSubscribe(name) {
      const { topic, qos } = this.subscription
      this.client.subscribe(topic+name, { qos }, (error, res) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        this.subscribeSuccess = true
        console.log('Subscribe to topics res', res)
      })
    },

    // unsubscribe topic
    // https://github.com/mqttjs/MQTT.js#mqttclientunsubscribetopictopic-array-options-callback
    doUnSubscribe() {
      const { topic } = this.subscription
      this.client.unsubscribe(topic, error => {
        if (error) {
          console.log('Unsubscribe error', error)
        }
      })
    },
    // publish message
    // https://github.com/mqttjs/MQTT.js#mqttclientpublishtopic-message-options-callback
    doPublish() {
      const { topic, qos, payload } = this.publish
      this.client.publish(topic, payload, { qos }, error => {
        if (error) {
          console.log('Publish error', error)
        }
      })
    },
    // disconnect
    // https://github.com/mqttjs/MQTT.js#mqttclientendforce-options-callback
    destroyConnection() {
      if (this.client.connected) {
        try {
          this.client.end(false, () => {
            this.initData()
            console.log('Successfully disconnected!')
          })
        } catch (error) {
          console.log('Disconnect failed', error.toString())
        }
      }
    },
    handleProtocolChange(value) {
      this.connection.port = value === 'wss' ? '8084' : '8083'
    },
  },

}
</script>

<style lang='scss'>
/*全局占比*/
.allTotal {
  justify-content: space-between;
  display: flex;
  height: 100%;
  border-radius: 4px;
  width: 100%;
  position: fixed;
}

.button_card_red {
  width: 25px;
  height: 72px;
  background-color: rgb(242, 97, 97);
  margin-top: -64px;
  margin-left: 35%;
  text-align: center;
  border-radius: 4px;
  position: fixed;
}

.button_card_red_huawei {
  width: 25px;
  height: 70px;
  background-color: rgb(242, 97, 97);
  margin-top: -58px;
  margin-left: 35%;
  text-align: center;
  border-radius: 4px;
  position: fixed;
}

.button_card {
  background-color: rgb(56, 148, 255);
  width: 25px;
  height: 9%;
  margin-top: -63px;
  margin-left: 34.5%;
  text-align: center;
  border-radius: 4px;
  position: fixed;
}

.button_card_huawei {
  background-color: rgb(56, 148, 255);
  width: 25px;
  height: 70px;
  margin-top: -58px;
  margin-left: 35%;
  text-align: center;
  border-radius: 4px;
  position: fixed;
}

.alarm_box {
  width: calc(50% - 9px);
  height: 50px;
  box-sizing: border-box;
}

.bar_bottom {
  color: rgb(86, 164, 255);
}

.bottom_line {
  width: 71%;
  margin-top: 6px;
  height: 3px;
  background-color: rgb(86, 164, 255);
}

.bar_false {
  width: 75%;
  margin-top: 4px;
  height: 3px
}

.box {
  width: calc(47% - 71px);
  height: 50px;
  box-sizing: border-box;
}

.device-box {
  width: calc(53% - 110px);
  height: 50px;
  text-align: center;
  box-sizing: border-box;
}

.device-box:not(:last-child) {
  border-right: 1px solid rgb(197, 197, 197); /* 加竖线，颜色和宽度可以根据需要调整 */
  //padding-left: 10px; /* 加竖线后的item左侧的额外padding */
}

.device_info {
  height: 75%;
  margin-top: 10%;
  width: 95%;
  margin-left: 10px;
}

.device_data_text {
  font-size: 18px;
  font-weight: 550
}

.device_data_list {
  width: 92%;
  height: 15%;
  margin-left: 2.5%;
  margin-top: 10%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.alarm_info {
  width: 88%;
  height: 247px;
  display: flex;
  position: fixed;
  flex-wrap: wrap;
  justify-content: space-between;
}

.alarm_card_info {
  height: 75%;
  margin-top: 10%;
  width: 93%;
  margin-left: 1%;
}

.info_left_signal {
  width: 1%;
  height: 100%;
  border-radius: 28px 0px 0px 28px;
  background-color: rgb(64, 189, 196);
}

.set_images {
  margin-left: 260px;
  margin-top: -19px;
}

.text_type {
  margin-left: 17px;
  margin-top: -42px;
  font-size: 16px;
  font-weight: 550;
}

.text_address {
  font-size: 16px;
  margin-left: 17px;
  margin-top: -44px;
  font-weight: 550;
}

.device_info_font {
  font-size: 16px;

}

.device_info_code {
  font-size: 16px;
  margin-left: 15px;
}

.info_one {
  height: 30px;
  padding-top: -2px;
}

.info_two {
  height: 30px;
  margin-top: -10px;
}

.info_three {
  height: 30px;
  margin-top: -8px;
}

.top_item_title {
  font-size: 18px;
  color: rgb(73, 93, 126);
  font-weight: 550;
  margin-top: 6px;
}

.list-item {
  width: 95%;
  height: 90px;
  margin-left: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.border_da_1 {
  width: 70%;
  margin-left: 10px;
  height: 1px;
  border: none;
  border-top: 1px dashed rgb(255, 201, 198);
}

.border_da_0 {
  width: 70%;
  margin-left: 10px;
  height: 1px;
  border: none;
  border-top: 1px dashed rgb(145, 213, 255);
}

.bottom_center {
  margin-top: 205%;
  margin-right: 40%;
}

/*头部导航栏*/
.top_taber {
  width: 100%;
  height: 20%;
  margin-left: 10px;
  margin-top: 20px;
}

.one {
  .el-card__body,
  .el-main {
    height: 70px;
    background-color: rgb(255, 241, 240);
    border: 1px solid rgb(255, 163, 158);
    border-radius: 4px;
    padding: 0px !important;
  }
}

.card_info {
  width: 97%;
  height: 360%;
  margin-top: 3%;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
}

.two {
  .el-card__body,
  .el-main {
    height: 70px;
    border: 1px solid rgb(145, 213, 255);
    border-radius: 4px;
    background-color: rgb(231, 247, 255);
    padding: 0px !important;
  }
}

</style>

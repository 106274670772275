export default {
  bind(el, { value }) {
    el.$value = value
    el.handler = e => {
      //阻止冒泡
      e.stopPropagation()
      //创建提示元素
      let addelement = (text, color) => {
        //获取鼠标位置
        let scrollX = document.documentElement.scrollLeft || document.body.scrollLeft
        let scrollY = document.documentElement.scrollTop || document.body.scrollTop
        let x = e.pageX || e.clientX + scrollX
        let y = e.pageY || e.clientY + scrollY
        const $mes = document.createElement('span')
        $mes.setAttribute('class', 'Copyspan')
        $mes.style = `position:fixed;left:${x + 6}px;top:${y -
          14}px;y-index:9999;font-size:12px; color:#343435; animation: Copyspan 800ms ease-in-out;color:${color}`
        $mes.innerText = text
        document.body.appendChild($mes)
        //500ms后移除
        setTimeout(() => {
          document.body.removeChild($mes)
        }, 500)
      }
      if (!el.$value) {
        addelement('', '#f56c6c')
        return
      }
      //创建copy元素textarea
      const $textarea = document.createElement('textarea')
      $textarea.readOnly = 'readonly'
      $textarea.style = {
        position: 'absolute',
        left: '-9999px',
      }
      //赋值copy内容
      $textarea.value = el.$value
      document.body.appendChild($textarea)
      $textarea.select()
      const result = document.execCommand('Copy')
      if (result) {
        addelement('', '#67c23a')
      }
      document.body.removeChild($textarea)
    }
    el.addEventListener('click', el.handler)
  },
  //监听vnode变化后重新获取value
  componentUpdated(el, { value }) {
    el.$value = value
  },
  //移除事件
  unbind(el) {
    el.removeEventListener('click', el.handler)
  },
}

import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//复制
import copy from '../src/utils/copy/copy'
// import router from './router/index'
Vue.config.productionTip = false
Vue.use(ElementUI)
import axios from "axios";
Vue.prototype.$axios=axios
Vue.config.productionTip = false
Vue.directive('copy',copy)
new Vue({
  render: h => h(App),
}).$mount('#app')
